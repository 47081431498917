<template>
    <b-container fluid class="about-cooperate">
        <b-container class="banner">
            <img
                src="~@/assets/images/mock/abouts/banner-2.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('cooperationWithPIF') }}</div>
            </div>
        </b-container>
        <b-container fluid class="px-0 bg-different s1-chance">
            <b-container>
                <b-row class="align-items-center">
                    <b-col cols="12" lg="6" md="5">
                        <div class="title text-left">
                            {{ $t('investmentOpportunity') }}
                        </div>
                        <div class="description">
                            <p>
                                {{ $t('stableProfit') }}
                                <br />{{ $t('investmentTrustProgram') }}
                            </p>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="6" md="7">
                        <div class="box-detail">
                            <img
                                src="~@/assets/images/mock/abouts/chance.png"
                                alt=""
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="px-0 s2-connect">
            <b-container>
                <b-row>
                    <b-col cols="12">
                        <div class="title text-center">
                            {{ $t('operationSupport') }}
                        </div>
                    </b-col>
                    <b-col lg="4" cols="12">
                        <div class="box">
                            <img
                                src="~@/assets/images/mock/abouts/connect-1.png"
                                alt=""
                            />
                            <div class="description">
                                <p>
                                    {{ $t('startupCommunity') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4" cols="12">
                        <div class="box">
                            <img
                                src="~@/assets/images/mock/abouts/connect-2.png"
                                alt=""
                            />
                            <div class="description">
                                <p>
                                    {{ $t('startupsParticipating') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4" cols="12">
                        <div class="box">
                            <img
                                src="~@/assets/images/mock/abouts/connect-3.png"
                                alt=""
                            />
                            <div class="description">
                                <p>
                                    {{ $t('startupsFindTheirStrategic') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="10" class="mx-auto">
                        <div class="title mb-1">
                            {{ $t('investmentPortfolio') }}
                        </div>
                        <div class="sub-title mb-5">
                            {{ $t('portfolioOfIndustries') }}
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container
            fluid
            class="section-content"
            v-for="(represent, idx) in listRepresent"
            :key="`represent-${represent.id}`"
            :data-aos="idx % 2 === 0 ? 'fade-left' : 'fade-right'"
        >
            <b-container>
                <b-row class="row-full">
                    <b-col cols="12" md="7" lg="6">
                        <div class="title">{{ represent.title }}</div>
                        <div class="description">
                            <p
                                v-if="represent.about"
                                v-html="represent.about"
                            ></p>
                        </div>
                        <div class="button-redirect">
                            <a
                                href="https://system.pifund.io/"
                                target="_blank"
                                class="btn-r"
                            >
                                {{ $t('investNow') }}
                            </a>
                        </div>
                    </b-col>
                    <b-col cols="12" md="5" lg="6">
                        <div class="project-item">
                            <div class="project-img">
                                <img
                                    :src="
                                        require(`@/assets/images/mock/abouts/${represent.img}.png`)
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="project-line"></div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </b-container>
</template>

<script>
export default {
    computed: {
        listRepresent() {
            const list = [
                {
                    id: 1,
                    img: 'giai-tri',
                    title: this.$t('entertainment'),
                    about: `${this.$t('twoMainInvestment')}
                          <br />
                          - ${this.$t('realEstateModel')}
                           <br />
                          - ${this.$t('entertainmentProducts')}`,
                },
                {
                    id: 2,
                    img: 'tham-mi',
                    title: this.$t('beauty'),
                    about: `${this.$t('beautySalonFacility')} <br />
                            ${this.$t('theBeautyMarket')}<br />
                            ${this.$t('theCompoundAnnual')}`,
                },
                {
                    id: 3,
                    img: 'cong-nghe',
                    title: this.$t('technology'),
                    about: `${this.$t('technologyStartups')} <br />
                    ${this.$t('fastestGrowingMarkets')}`,
                },
                {
                    id: 4,
                    img: 'bat-dong-san',
                    title: this.$t('realEstate'),
                    about: `${this.$t('constructionPlanningProjects')}<br />
                    ${this.$t('marketFullOfOpportunities')}`,
                },
            ];
            return list;
        },
    },
};
</script>

<style lang="scss">
.about-cooperate {
    padding: 50px 0;
    .bg-different {
        background-color: #f7f7f7;
    }
    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (min-width: 1400px) {
            max-width: 1320px;
        }
        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 35px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 26px;
                }
                @media (max-width: 767px) {
                    font-size: 22px;
                }
            }
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
    .title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 35px;
        color: #003189;
        word-break: break-word;
        @media (max-width: 575px) {
            font-size: 27px;
        }
    }

    .s1-chance {
        padding: 20px 0;
        margin-top: 50px;
        .title {
            font-family: 'Roboto', sans-serif;
            color: #003189;
            font-size: 32px;
            font-weight: 600;
            text-align: left;
            text-transform: uppercase;
            line-height: 120%;
            margin-bottom: 30px;
            position: relative;
            padding-bottom: 0px;
            @media (max-width: 991px) {
                font-size: 25px;
            }
        }
        .description {
            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 130%;
                span {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
        .box-detail {
            width: 100%;
            max-width: 400px;
            margin: auto;
            height: auto;
            // overflow: hidden;
            &:hover {
                img {
                    transform: scale(1.05);
                    transition: all 1s;
                }
            }
            img {
                width: 100%;
                height: 100%;
            }
            @media (max-width: 767px) {
                margin: 20px auto;
            }
        }
    }
    .s2-connect {
        padding: 20px 0;
        .row {
            @media (max-width: 991px) {
                .box {
                    display: flex;
                    width: 100%;
                    max-width: 100%;
                    align-items: flex-start;
                    justify-content: space-around;
                    img {
                        flex: 1 1 auto;
                        margin-right: 30px;
                        max-width: 250px;
                    }
                    .description {
                        flex: 1 1 auto;
                        max-width: 300px;
                        p {
                            text-align: left;
                            text-align-last: left;
                        }
                    }
                }
            }
            @media (max-width: 575px) {
                .box {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 25px;
                    img {
                        flex: unset;
                        margin-right: auto;
                        margin: 0 auto 10px;
                    }
                    .description {
                        flex: 0 0 100%;
                        p {
                            text-align: justify;
                            text-align-last: center;
                        }
                    }
                }
            }
        }
        .sub-title {
            color: #003189;
            text-align: center;
            font-weight: 500;
            font-style: italic;
        }
        .title {
            margin-bottom: 30px;
        }
        .box {
            max-width: 300px;
            width: 100%;
            img {
                width: 100%;
                max-width: 275px;
                height: auto;
                margin: 0 auto 30px;
            }
            .description {
                p {
                    font-size: 16px;
                    font-weight: 800;
                    color: #000;
                    text-align: justify;
                    text-align-last: center;
                }
            }
        }
    }
    .section-content {
        padding: 20px 0;
        &:nth-child(even) {
            background-color: #f7f7f7;
        }
        @media (min-width: 768px) {
            &:nth-child(even) {
                .row-full {
                    .col-md-7:first-child {
                        order: 2;
                        .title {
                            &:before {
                                right: auto;
                                left: 0;
                            }
                            &:after {
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                    .project-item {
                        margin: 20px auto 20px 0;
                    }
                }
            }
            &:nth-child(odd) {
                .row-full {
                    .project-item {
                        margin: 20px 0 20px auto;
                    }
                }
            }
        }

        .row-full {
            overflow: hidden;
            align-items: center;
            padding: 20px 0;
            .title-section {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    height: 4px;
                    width: calc(100% - 10px);
                    background: #003189;
                }
                // &:after {
                // }
                &:before {
                    right: 0;
                    left: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    border: 3px solid #003189;
                    bottom: 0;
                }
            }
            .description {
                p {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 130%;
                    span {
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
            }
            .button-redirect {
                a {
                    background: linear-gradient(
                        172deg,
                        #237fff 0%,
                        #0930ff 100%
                    );
                    // border: 2px solid #fff;
                    text-decoration: none;
                    transition: all 0.5s;

                    min-height: 35px;
                    min-width: 130px;
                    max-width: 150px;
                    padding: 5px 10px;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
                    color: #fff;
                    font-weight: 600;
                    text-decoration: none;
                    font-size: 18px;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    box-shadow: 1px 2px 10px 2px
                        rgba($color: #000000, $alpha: 0.3);

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px auto;
                width: 100%;
                max-width: 400px;
                position: relative;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                }
                .project-img {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .content-project {
                            opacity: 1;
                            transition: all 1s;
                        }
                    }
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        position: relative;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
                .project-name {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    p {
                        text-align: center;
                        display: -webkit-box;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 0px;
                        @media (max-width: 575px) {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
                .project-line {
                    width: 100%;
                    height: 8px;
                    background-color: #363795;
                    border-radius: 10px;
                    position: absolute;
                    bottom: 0;
                    z-index: 2;
                }
            }
        }
        @media (max-width: 767px) {
            .row-full {
                .project-item {
                    max-width: unset;
                    .project-img {
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        img {
                            border-radius: 10px;
                            width: 100%;
                            height: auto;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            transform: unset;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 575px) {
        padding: 25px 10px;
        .banner {
            min-height: 250px;
        }
    }
}
</style>
